import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
// import TopbarNavDashboards from './TopbarNavDashboards';
// import TopbarNavUIElements from './TopbarNavUIElements';
// import TopbarNavOtherPages from './TopbarNavOtherPages';

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';
const isCNVersion = process.env.REACT_APP_VERSION.startsWith('cn');

const TopbarNav = ({ t }) => (
  /* <nav className="topbar__nav">
    <Link className="topbar__nav-link" to="/home">Home</Link>
    <Link className="topbar__nav-link" to="/about">About Us</Link>
    <Link className="topbar__nav-link" to="/contact">Contact Us</Link>
  </nav> */
  <nav className="topbar__menu-nav">
    {!isMaintenance && !isCNVersion &&
      <Link to="/features">
        <button>
          {t('footer.features')}
        </button>
      </Link>}
    {!isMaintenance && !isCNVersion &&
      <Link to="/organizers">
        <button>
          {t('footer.organizers')}
        </button>
      </Link>}
    {!isMaintenance &&
      <Link to="/joybot_download20211216">
        <button>
          {t('footer.product_download')}
        </button>
      </Link>}
    {!isMaintenance &&
      <Link to="/about">
        <button>
          {t('footer.aboutus')}
        </button>
      </Link>}
    {!isMaintenance &&
      <Link to="/contact">
        <button>
          {t('footer.contact')}
        </button>
      </Link>}
    {/* <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
      <button>
        {t('footer.login')}
      </button>
    </a> */}
  </nav>
);

TopbarNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarNav);
